import React, {useState, useEffect} from "react";
import {Checkbox, InputNumber, Space, Steps} from "antd";
import "antd/dist/antd.css";
import {
    Button,
    DatePicker,
    Form,
    Input,
    Radio,
    Select,
    Upload,
    Result,
    message,
    Alert,
    Spin,
} from "antd";
import {
    MinusCircleOutlined,
    PlusOutlined,
    InboxOutlined,
    RightOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
} from "@ant-design/icons";

import {Link} from "gatsby";

import data from "../data/sezioni-categorie";
import scuoleMedie from "../data/scuole-medie";
import scuoleSuperiori from "../data/scuole-superiori";
import axios from "axios";
import NavBar from "../components/navBar";
import {Helmet} from "react-helmet";

const {Option} = Select;
const {Dragger} = Upload;

const {Step} = Steps;

function FromOnlinePage() {
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(true);
    const [sezione, setSezione] = useState(false);
    const [scuola, setScuola] = useState(false);
    const [categoria, setCategoria] = useState(false);
    const [formData, setFormData] = useState(false);
    const [youtubeError, setYoutubeError] = useState("notset");

    useEffect(() => {
        function isInThePast(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        }

        function isInTheFuture(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date > today;
        }

        //inserire qui sotto la deadline
        if (
            isInThePast(new Date("2023-05-12")) ||
            isInTheFuture(new Date("2022-11-5"))
        ) {
            /*  window.location.href =
              "https://concorsopaoloferro.edu.it/iscrizioni-chiuse"; */
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, []);

    function CategoryButton({index, name, description, scuola}) {
        if (step === 0)
            return (
                <button
                    onClick={() => {
                        setSezione(index);
                        setStep(1);
                    }}
                >
                    <span>{name} </span>
                    {description}
                </button>
            );
        return (
            <button
                onClick={() => {
                    setCategoria(index);
                    setStep(2);
                    setScuola(scuola);
                }}
            >
                <span>{name} </span>
                {description}
            </button>
        );
    }

    const onFinish = (values) => {
        setFormData(values);
        setStep(3);
        console.log("Success:", values);
    };

    function youtube_parser(url) {
        var regExp =
            /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return match && match[7].length == 11 ? match[7] : false;
    }

    function submit(values) {
        console.log(formData);
        setLoading(true);
        axios
        .post(
            "https://concorsopaoloferro.edu.it/concorso-api/forms/online-inscription.php",
            {
                sezione: sezione,
                categoria: categoria,
                nome: formData["Nome"],
                cognome: formData["Cognome"],
                dataNascita: formData["Data di nascita"],
                nomeGruppo: formData["Nome gruppo"],
                luogoNascita: formData["luogo di nascita"],
                istituto: formData["Nome istituto"],
                nomeInsegnante: formData["Nome Insegnante"],
                telefonoInsegnante: formData["Numero Insegnante"],
                emailInsegnante: formData["email insegnante"],
                emailGenitore: formData["email genitore"],
                iban: formData["iban"],
                programma: JSON.stringify(formData["brani"]),
                idYoutube: youtube_parser(values["Link Youtube"]),
                copiaVersamento: formData['copia del versamento'].fileList,
                partiture: values.partiture.fileList,
                codiceFiscale: formData["Codice Fiscale"],
                numeroPartecipanti: formData['Numero di partecipanti']
            },
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
        .then((result) => {
            console.log(result.data);
            setLoading(false);
            setStep(4);
        });
    }

    return (
        <>
            <Helmet>
                <title>Iscrizione concorso online</title>
                <link
                    rel="canonical"
                    href="https://concorsopaoloferro.edu.it/canonical-page/"
                />
            </Helmet>
            <NavBar></NavBar>
            <main>
                {loading && (
                    <div
                        style={{
                            position: "fixed",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(255,255,255, 1)",
                            top: 0,
                            right: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Spin size="large" tip="Solo un attimo..."></Spin>
                    </div>
                )}

                <h1 style={{textAlign: "center", marginTop: 100, marginBottom: 60}}>
                    Iscrizione al concorso online
                </h1>
                {step === 0 && (
                    <div className="catSelect-w">
                        {data.map((item, index) => {
                            return (
                                <CategoryButton
                                    index={index}
                                    name={item.nome}
                                    description={item.descrizione}
                                />
                            );
                        })}
                    </div>
                )}
                {step === 1 && (
                    <div className="catSelect-w">
                        {data[sezione].categorie.map((item, index) => {
                            return (
                                <CategoryButton
                                    index={index}
                                    name={item.nome}
                                    description={item.descrizione}
                                    scuola={item.scuola}
                                />
                            );
                        })}
                    </div>
                )}
                {step === 2 && (
                    <div style={{maxWidth: "500px", margin: "auto"}}>
                        <Form
                            className="formIscrizione"
                            labelCol={{
                                span: 4,
                            }}
                            wrapperCol={{
                                span: 14,
                            }}
                            layout="vertical"
                            size="large"
                            onFinish={onFinish}
                        >
                            {sezione !== 3 && (
                                <>
                                    <Form.Item
                                        rules={[{required: true}]}
                                        requiredMark="optional"
                                        label="Nome"
                                        name="Nome"
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item
                                        rules={[{required: true}]}
                                        requiredMark="optional"
                                        name="Cognome"
                                        label="Cognome"
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item
                                        rules={[{required: true}]}
                                        name="Data di nascita"
                                        requiredMark="optional"
                                        label="Data di Nascita"
                                    >
                                        <DatePicker format={"DD/MM/YYYY"}/>
                                    </Form.Item>
                                    <Form.Item
                                        rules={[{required: true}]}
                                        name="luogo di nascita"
                                        requiredMark="optional"
                                        label="Luogo di Nascita"
                                    >
                                        <Input/>
                                    </Form.Item>
                                </>
                            )}
                            <Form.Item
                                rules={[{required: true}]}
                                name="Codice Fiscale"
                                requiredMark="optional"
                                label={
                                    sezione === 3
                                        ? "Codice fiscale della scuola"
                                        : sezione === 2 ? "Codice fiscale di uno dei partecipanti" :
                                            "Codice fiscale"
                                }
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                rules={[{required: true}]}
                                name="Nome istituto"
                                requiredMark="optional"
                                label={"Istituto di provenienza"}
                            >
                                <Select
                                    showSearch
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.includes(input.toLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children
                                        .toLowerCase()
                                        .localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    {scuola === "media"
                                        ? scuoleMedie.map((item, index) => {
                                            return (
                                                <Option value={item} key={item.toLowerCase() + index}>
                                                    {item.toLowerCase()}
                                                </Option>
                                            );
                                        })
                                        : scuoleSuperiori.map((item, index) => {
                                            return (
                                                <Option value={item} key={item.toLowerCase() + index}>
                                                    {item.toLowerCase()}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                            {sezione > 1 && (
                                <>
                                    <Form.Item
                                        style={{width: '100%'}}
                                        label="Nome gruppo"
                                        rules={[{required: true}]}
                                        name="Nome gruppo"
                                        requiredMark="optional"
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Numero di partecipanti"
                                        rules={[{required: true}]}
                                        name="Numero di partecipanti"
                                        requiredMark="optional"
                                    >
                                        <InputNumber style={{width: '100%'}}/>
                                    </Form.Item>
                                </>


                            )}
                            <Form.Item
                                label="Nome e cognome insegnante"
                                rules={[{required: true}]}
                                name="Nome Insegnante"
                                requiredMark="optional"
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Numero di telefono insegnate"
                                rules={[{required: true}]}
                                name="Numero Insegnante"
                                requiredMark="optional"
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                requiredMark="optional"
                                name="email insegnante"
                                rules={[{required: true}]}
                                label="Email insegnante"
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="email genitore"
                                label="Email genitore"
                                requiredMark="optional"
                                rules={[
                                    {
                                        type: "email",
                                        message: "E-mail non valida",
                                    },
                                    {
                                        required: true,
                                        message: "E-mail non valida",
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="iban"
                                label="IBAN dove ricevere i premi"
                                requiredMark="optional"
                                rules={[{required: true}]}
                            >
                                <Input/>
                            </Form.Item>
                            <div style={{marginBottom: 8}}>
                                Programma in ordine di esecuzione
                            </div>
                            <Form.List name="brani" rules={[{required: true}]}>
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map(({key, name, ...restField}) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                                key={key}
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    name={name}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Inserire titolo brano",
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Titolo del brano"/>
                                                </Form.Item>

                                                <MinusCircleOutlined
                                                    onClick={() => remove(name)}
                                                    style={{marginTop: "14px", marginLeft: "10px"}}
                                                />
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined/>}
                                            >
                                                Aggiungi brano
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <Form.Item
                                required
                                rules={[{required: true}]}
                                label="Copia del versamento in PDF"
                                name="copia del versamento"
                            >
                                <Dragger
                                    accept=".pdf"
                                    beforeUpload={(file) => {
                                        console.log(file);
                                        return false;
                                    }}
                                    onChange={(info) => {
                                        const {status} = info.file;
                                        if (status !== "uploading") {
                                            console.log(info.file, info.fileList);
                                        }
                                        if (status === "done") {
                                            message.success(
                                                `${info.file.name} file uploaded successfully.`
                                            );
                                        } else if (status === "error") {
                                            message.error(`${info.file.name} file upload failed.`);
                                        }
                                    }}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined/>
                                    </p>
                                    <p className="ant-upload-text">Carica la ricevuta del versamento</p>
                                    <p className="ant-upload-hint">
                                        Clicca o trascina i file per caricarli. Sono ammessi solo
                                        file PDF
                                    </p>
                                </Dragger>
                            </Form.Item>
                            <Form.Item style={{textAlign: "center", marginTop: 100}}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{width: "80%", borderRadius: 30}}
                                >
                                    Prosegui ➜
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                )}
                {step === 3 && (
                    <div style={{maxWidth: "500px", margin: "auto"}}>
                        <Form
                            layout="vertical"
                            onFinish={!youtubeError && submit}
                            enctype="multipart/form-data"
                        >
                            <Form.Item
                                label="Link Youtube"
                                rules={[{required: true}]}
                                name="Link Youtube"
                                requiredMark="optional"
                            >
                                <Input
                                    onChange={(e) => {
                                        if (!youtube_parser(e.target.value)) {
                                            setYoutubeError(true);
                                        } else {
                                            setYoutubeError(false);
                                        }
                                    }}
                                />
                            </Form.Item>
                            {youtubeError === "notset" ? (
                                <div></div>
                            ) : youtubeError === false ? (
                                <div
                                    style={{
                                        color: "green",
                                        textAlign: "right",
                                        marginTop: -16,
                                        fontWeight: 700,
                                    }}
                                >
                                    <CheckCircleOutlined/> Link Youtube valido
                                </div>
                            ) : youtubeError === true ? (
                                <div
                                    style={{
                                        color: "red",
                                        textAlign: "right",
                                        marginTop: -16,
                                        fontWeight: 700,
                                    }}
                                >
                                    {" "}
                                    <CloseCircleOutlined/> Link Youtube non valido
                                </div>
                            ) : null}
                            <Form.Item
                                required
                                rules={[{required: true}]}
                                label="Partiture in PDF"
                                name="partiture"
                                requiredMark="optional"
                            >
                                <Dragger
                                    multiple
                                    accept=".pdf"
                                    beforeUpload={(file) => {
                                        console.log(file);
                                        return false;
                                    }}
                                    onChange={(info) => {
                                        const {status} = info.file;
                                        if (status !== "uploading") {
                                            console.log(info.file, info.fileList);
                                        }
                                        if (status === "done") {
                                            message.success(
                                                `${info.file.name} file uploaded successfully.`
                                            );
                                        } else if (status === "error") {
                                            message.error(`${info.file.name} file upload failed.`);
                                        }
                                    }}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined/>
                                    </p>
                                    <p className="ant-upload-text">Carica le partiture</p>
                                    <p className="ant-upload-hint">
                                        Clicca o trascina i file per caricarli. Sono ammessi solo
                                        file PDF
                                    </p>
                                </Dragger>
                            </Form.Item>

                            {/* <Form.Item style={{ marginTop: 70 }}>
              <Checkbox required>Accetto i termini e condizioni</Checkbox>
            </Form.Item> */}
                            <Form.Item rules={[{required: true}]}>
                                <Checkbox required>Liberatoria sulla privacy</Checkbox>
                            </Form.Item>
                            <Form.Item style={{textAlign: "center", marginTop: 100}}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{width: "80%", borderRadius: 30}}
                                >
                                    Iscriviti
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                )}
                {step === 4 && (
                    <div>
                        <Result
                            icon={<SmileOutlined/>}
                            title="Abbiamo ricevuto la tua iscrizione!"
                            subTitle="Riceverai un-email di conferma agli indirizzi: "
                            extra={
                                <Link to="/">
                                    <Button type="primary">Torna alla homepage</Button>
                                </Link>
                            }
                        />
                    </div>
                )}
                <div style={{padding: "100px 0px"}}>
                    <Steps current={step}>
                        <Step title="Sezione"/>
                        <Step title="Categoria"/>
                        <Step title="Dati"/>
                        <Step title="Link"/>
                        <Step title="Fatto!"/>
                    </Steps>
                </div>
            </main>
        </>
    );
}

export default FromOnlinePage;
